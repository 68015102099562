import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ArticleHTML from '../general/util/ArticleHTML'
import ProductAds from '../general/article/ProductAds'
import ArticleAuthorList from '../general/article/ArticleAuthorList'
import Comparisons from '../general/article/Comparisons'
import Image from '../../components/general/util/Image'
import ShareButtons from '../general/widgets/ShareButtons'
import KeywordList from '../general/lists/KeywordList'
import SideStories from '../general/article/SideStories'
import WeightTable from '../general/article/WeightTable'
import Accordion from '../general/article/Accordion'
import articleTypes from '../general/article/ArticleTypes'
import RelatedArticleList from '../general/article/RelatedArticleList'
import ArticleComments from '../general/comments/ArticleComments'
import Paywall from '../widgets/Paywall'
import PaywallBanner from '../widgets/PaywallBanner'
import MostReadPaid from '../widgets/MostReadPaid'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import { AdsForDesktop, AdsForMobile, AdSlotDesk1, AdSlotMob1 } from '../general/ads/Ads'
import { SubscriberIcon } from '../widgets/Icons'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { getURL } from '@otavamedia/om-component-library/lib/lib/WPClient'
import some from 'lodash/some'
import PrevNextBox from '../widgets/PrevNextBox'
import DigimagBox from '../widgets/DigimagBox'
import BestProducts from '../widgets/BestProducts'
import Timeline from '../widgets/Timeline'
import { enableScrollAnimations, disableScrollAnimations } from '@otavamedia/om-component-library/lib/lib/utils'
import PopularPosts from '../general/popular/PopularPosts'
import NettixEmbed from '../general/nettix/NettixEmbed'
import OrderCTA from '../widgets/OrderCTA'
import GAMNativeAdHolder from '../widgets/GAMNativeAdHolder'
import Themebox from '../general/article/Themebox'
import CrossLinkTopNotification from '../widgets/CrossLinkTopNotification'
import CrossLinkBottomNotification from '../widgets/CrossLinkBottomNotification'
import AuthorWidget from '../widgets/AuthorWidget'
import eraLogo from '../../assets/logos/era-logo.svg'
import ReportError from '../general/widgets/ReportError'

import './FeatureArticle.scss'

@connect({
  props: [
    magazineStore, [
      'getCurrentArticleTitle',
    ],
    auth, [
      'premiumUser',
      'shareTokenAccess'
    ]
  ]
})
  /**
   * This renders a feature article (Digilehden artikkelin Feature-leiska), which is mostly the same as a
   * MagazineArticle, but with slightly different layout. It renders all content on the page between the top row ad
   * and the footer. These articles don't have a sidebar.
   *
   * The Article component renders this component.
   */
class FeatureArticle extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    payWall: PropTypes.bool,
    getCurrentArticleTitle: PropTypes.string,
    noManualComparisonPlacement: PropTypes.bool,
    premiumUser: PropTypes.bool,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
    shareTokenAccess: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = { nextArticle: {}, previousArticle: {}, articlesLoaded: false }
  }

  async loadPrevNextArticles (props, state) {
    const prev = await this.maybeLoadArticle('previousArticle', props, state)
    const next = await this.maybeLoadArticle('nextArticle', props, state)
    const newState = { ...prev, ...next }
    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  async maybeLoadArticle (propName, props, state) {
    const newState = {}
    if (props[propName] && props[propName].link && !state.articlesLoaded) {
      if (!props[propName].image) {
        // no image, load article
        newState[propName] = (await WP.getForURL(getURL(props[propName].link))).data
      } else {
        newState[propName] = props[propName]
      }
      newState.articlesLoaded = true
    }
    return newState
  }

  async componentDidMount () {
    this.loadPrevNextArticles(this.props, this.state, false)

    enableScrollAnimations('.alignnone div, .FeatureArticle_header-image-container img, .alignnone p:not(.wp-caption-text), .FeatureArticle_header-image-container video')
  }

  componentWillUnmount () {
    disableScrollAnimations()
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.articlesLoaded) {
      this.loadPrevNextArticles(this.props, this.state)
    }
  }

  renderVignetteAndTopic = article => {
    if (article.vignette && article.topic) {
      return (
        <Fragment>
          <HTML>{article.vignette}</HTML>: <HTML>{article.topic}</HTML>
        </Fragment>
      )
    } else if (article.vignette) {
      return <HTML>{article.vignette}</HTML>
    } else {
      return null
    }
  }

  renderHeaderImage = (video, featuredMedia, mobileHero) => {
    return video
      ? (
        <div styleName="header-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
      : <Fragment>
        <div styleName={mobileHero ? 'header-image-container not-mobile' : 'header-image-container'}>
          <Image addToGallery data={featuredMedia} size={IMAGE_SIZE.LARGE}
            sizes="(max-width: 1440px) 100vw, 1440px" isZoomed/>
        </div>
        {mobileHero
          ? <div styleName="header-image-container mobile">
            <Image addToGallery data={mobileHero} size={IMAGE_SIZE.LARGE}
              sizes="(max-width: 1440px) 100vw, 1440px" isZoomed/>
          </div>
          : null}
      </Fragment>
  }

  renderMainImage = (video, featuredMedia = {}) => {
    if (video) {
      return (
        <div styleName="main-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
    }

    if (!featuredMedia) {
      return <div styleName="main-image-container"></div>
    }

    const { copyright, caption } = featuredMedia
    return (
      <div styleName="main-image-container">
        <Image addToGallery data={featuredMedia} noHeight/>

        {(caption || copyright) && (
          <div styleName="caption-box">
            <figcaption><HTML>{caption}</HTML></figcaption>
            <div styleName="image-copyright"><HTML>{copyright}</HTML></div>
          </div>
        )}
      </div>
    )
  }

  renderHeader = (article, title, video) => {
    // const isBest = some(article.categories, {slug: 'testivoittaja'})
    const isResearcher = some(article.categories, (cat) => cat.slug === 'tutkijalta')

    return <Fragment>
      <header styleName="header">
        {this.renderHeaderImage(video, article.featuredMedia, article.mobileHero)}
      </header>
      <div styleName="title-area">
        {/*
          <div styleName={isBest ? 'category-row below-best-icon' : 'category-row'}>
            <div styleName="article-categories">
              {(article.categories && article.categories.length) && !article.isAd
                ? (<Fragment><CategoryList categories={article.categories} mainOnly /><ArticleMetaDivider /></Fragment>)
                : null}
              <ArticleDate date={article.createdDate} dateOnly/>
            </div>
          </div>
          */}
        {article.vignette
          ? (
            <div styleName="vignette">
              {this.renderVignetteAndTopic(article)}
            </div>
          )
          : null
        }
        <h1 styleName={article.capitalizeTitle ? 'capitalized' : ''}><HTML>{title}</HTML></h1>
        {article.ingress
          ? <div styleName="ingress"><div styleName="ingress-shadow"></div><div>{isResearcher &&
        <span styleName="researcher">Tutkijalta</span>}<HTML>{article.ingress}</HTML></div></div>
          : null}
      </div>
    </Fragment>
  }

  renderV2Header = (article, title, video) => {
    const isResearcher = some(article.categories, (cat) => cat.slug === 'tutkijalta')
    let mainTitleClass = ''
    let vignetteClass = 'vignette'
    if (article.extraTitleColour) {
      if (Number(article.extraTitleColour.replace('#', '0x')) < 0x808080) {
        // if image title colour is dark, use red main title
        // mainTitleClass = 'red'
        vignetteClass = 'vignette'
      }
    }

    return <Fragment>
      <header
        styleName={(article.extraVideo && this.state.scrolling ? 'header-v2 zoom' : 'header-v2') + (article.extraVideo ? ' extra-video' : '')}>
        {this.renderHeaderImage(video, article.featuredMedia, article.headerVideoMobile || article.mobileHero, this.state.scrolling, article.extraTitleColour, article.extraVideo)}
      </header>
      <div styleName="fixed-text">
        {!!article.runningHead2 && <div styleName="image-holder">
          <div styleName="img">
            <img src={eraLogo} alt=""/>
          </div>
          <div styleName="text">
            <HTML>{article.runningHead2}</HTML>
          </div>
        </div>}
        <div styleName="title-holder" style={{ color: article.extraTitleColour }}>
          <HTML>{article.extraTitle}</HTML>
        </div>
      </div>
      {article.extraVideo
        ? <Image addToGallery data={article.extraVideo} divStyle="secondary-video" isZoomed/>
        : null}
      <div styleName="title-area-v2">
        {article.vignette
          ? (
            <div styleName={vignetteClass}>
              {this.renderVignetteAndTopic(article)}
            </div>
          )
          : null
        }
        {article.extraVideo || article.extraTitleOnly ? null : <h1 styleName={article.capitalizeTitle ? 'capitalized ' + mainTitleClass : mainTitleClass}><HTML>{title}</HTML></h1>}
        {article.ingress
          ? <div styleName="ingress"><div styleName="ingress-shadow"></div><div>{isResearcher &&
            <span styleName="researcher">Tutkijalta</span>}<HTML>{article.ingress}</HTML></div></div>
          : null}
      </div>
    </Fragment>
  }
  renderCommentAuthor = article => {
    const { featuredMedia, commentator, authorOverride } = article

    return (
      <div styleName="comment-author">
        {featuredMedia && <figure>
          <div styleName="featured-image">
            <Image data={featuredMedia} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 576px) 250px, 100vw'} isZoomed/>
          </div>
        </figure>
        }
        <div styleName="comment-author-description">
          <ArticleAuthorList author={authorOverride || commentator}/>
        </div>
      </div>
    )
  }

  render () {
    const {
      article,
      payWall,
      getCurrentArticleTitle,
      noManualComparisonPlacement,
      premiumUser,
      shareTokenAccess,
    } = this.props

    const {
      id,
      content,
      pagination,
      headerVideoMp4,
      headerVideoWebm,
      comparisons,
      sidestories,
      categories,
      tags,
      weightTable,
      details,
      title,
      titleOverride,
      linkedProductCards,
      linkedProductMemories,
      createToc,
      relatedArticles,
      productAds,
      relatedAds,
      hideAds,
      disableAdCoin,
      isAd,
      timelineTag,
      originalArticle,
      nettixSearchId,
      author,
      articleType
    } = article
    if (article.featuredMedia && article.featuredMedia.src && article.featuredMedia.src.includes(window.om_constants.defaultImage)) {
      article.featuredMedia = null
    }
    const articleStyles = ['article', 'article-container']
    const isBest = some(article.categories, { slug: 'testivoittaja' })
    const isTest = some(article.tags, { slug: 'testipankki' })

    if (article.articleType !== articleTypes.MAGAZINE_COMMENT) articleStyles.push('special')
    if (article.articleType === articleTypes.FEATUREV2) articleStyles.push('feature-v2')
    if (isAd) articleStyles.push('is-ad')

    const textStyles = `article-text ${payWall ? 'paywall-fade' : ''}`

    const shownTitle = ((article.printmag || article.omAdMagazine || article.omThemeMagazine)
      ? (titleOverride || getCurrentArticleTitle || title)
      : (titleOverride || title))

    let ads = []
    if (payWall && linkedProductCards && linkedProductCards.length) {
      linkedProductCards.forEach((productCard) => {
        if (productCard.productAds && productCard.productAds.length) {
          ads = ads.concat(productCard.productAds)
        }
      })
    }

    return (
      <Fragment>
        <article styleName={articleStyles.join(' ')} id="magazine-article">
          {articleType === articleTypes.FEATUREV2
            ? this.renderV2Header(article, shownTitle, headerVideoMp4 || headerVideoWebm)
            : this.renderHeader(article, shownTitle, headerVideoMp4 || headerVideoWebm)
          }
          <div styleName="white-background">
            <div styleName="ingress-area">
              {article.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon/></div>}
              <div styleName={`article-meta-row ${article.articleType === articleTypes.MAGAZINE_COMMENT ? 'centered' : ''}`}>
                {article.articleType !== articleTypes.MAGAZINE_COMMENT
                  ? (
                    <div styleName="article-author">
                      <ArticleAuthorList author={article.author} photographer={article.photographer}
                        assistants={article.assistants}/>
                    </div>
                  )
                  : null
                }
                <div styleName="meta-row-share-buttons">
                  <ShareButtons shareCount={article.shareCount} article={article}/>
                </div>
              </div>
            </div>
            {originalArticle &&
              <div styleName="margins">
                <CrossLinkTopNotification text={originalArticle.topMessage}/>
              </div>
            }
            <div styleName={'body-container'}>
              <div styleName={'center-col'}>
                <PaywallBanner/>
              </div>
              {!payWall && noManualComparisonPlacement && comparisons && comparisons.length === 1
                ? (
                  <div styleName="comparison-container comparison-container-right">
                    <Comparisons data={comparisons} floated={true} short={payWall}/>
                  </div>
                )
                : null
              }
              <div styleName={`${textStyles}`} className={'article-body ' + (premiumUser ? 'user-level-4' : '') + (hideAds ? ' noAds' : '')}>
                {payWall
                  ? <ArticleHTML options={{ pagination }}>{content}</ArticleHTML>
                  : <ArticleHTML options={{ linkedProductCards, linkedProductMemories, goodbad: comparisons, id, pagination, createToc }}>{content}</ArticleHTML>
                }
              </div>
              {payWall
                ? <div styleName={'center-col'}>
                  <Paywall disableAdCoin={disableAdCoin}/>
                </div>
                : null}
              {/* payWall && linkedProductCards && linkedProductCards.length
                ? (
                  <div styleName="comparison-container">
                    <Comparisons data={linkedProductCards.map(card => card.productData)} short={payWall}/>
                  </div>
                )
                : null
              */}
              {!payWall && noManualComparisonPlacement && comparisons && comparisons.length > 1
                ? (
                  <div styleName="comparison-container">
                    <Comparisons data={comparisons} short={payWall}/>
                  </div>
                )
                : null
              }
              {weightTable ? weightTable.map((table, idx) => <WeightTable key={idx} data={table}/>) : null}
              {details && !!details.length && (
                <div styleName="details-wrapper">
                  <Accordion details={details} />
                </div>
              )}
              {!payWall ? <SideStories sidestories={sidestories} margins/> : null}
              <div styleName="margins">
                <AuthorWidget author={author}/>
              </div>
              {(productAds || relatedAds) && <div styleName="margins"><ProductAds ads={productAds} related={relatedAds} /></div>}
              {!!ads.length && <div styleName="center-col" style={{ marginTop: '1rem' }}><ProductAds ads={ads}/></div>}
              <div styleName={'center-col'}>
                <PaywallBanner showText={true}/>
              </div>
              <div styleName={'center-col'}>
                <OrderCTA categories={categories} tags={tags}/>
              </div>
              {article.forSubscribers && !premiumUser
                ? <Fragment>
                  <div styleName="bottom-navigation">
                    <PrevNextBox article={article}/>
                    <DigimagBox article={article}/>
                  </div>
                  <div styleName={'center-col'}><MostReadPaid exclude={[id]}/></div>
                </Fragment>
                : null}
              {originalArticle &&
                <div styleName="margins">
                  <CrossLinkBottomNotification title={originalArticle.title} text={originalArticle.siteDescription}
                    linkText={originalArticle.linkText} href={originalArticle.url}/>
                </div>
              }
              {article.linkedThemeBoxesData && (
                <div styleName='margins'>
                  {article.linkedThemeBoxesData.map((tbData, key) => <Themebox key={key} data={tbData}></Themebox>)}
                </div>
              )}
              <div styleName="meta-row-share-buttons-bottom center-col">
                <ShareButtons shareCount={article.shareCount} article={article} up/>
                <ReportError article={article}/>
              </div>
              <div styleName={'keywords margins'}>
                {
                  tags && tags.length
                    ? (
                      <Fragment>
                        <span styleName="title">Lisää aiheesta</span>
                        <Fragment>
                          <KeywordList keywords={tags}/>
                        </Fragment>
                      </Fragment>
                    )
                    : null
                }
              </div>
              {relatedArticles && relatedArticles.length > 0 && (
                <div styleName={'margins'}>
                  <RelatedArticleList related={relatedArticles} limit={4}/>
                </div>
              )}
              {nettixSearchId
                ? (
                  <div styleName="nettix-embed-container bottom-navigation">
                    <NettixEmbed searchId={nettixSearchId}/>
                  </div>
                )
                : null}
            </div>
            <div styleName="almost-full">
              {timelineTag ? <Timeline exclude={[article.id]} term={timelineTag}/> : null}
            </div>
          </div>
        </article>
        <div styleName="white-background bottom body-container">
          {isBest || isTest ? <div styleName="margins"><BestProducts isBest={isBest}/></div> : null}
          <div styleName="bottom-navigation margins">
            {!hideAds
              ? <Fragment>
                <AdsForDesktop>
                  <AdSlotDesk1 loadInstantly={true} />
                </AdsForDesktop>
                <AdsForMobile>
                  <AdSlotMob1 loadInstantly={true} />
                </AdsForMobile>
                <GAMNativeAdHolder grow={true} />
              </Fragment>
              : null}
            {!payWall && (!shareTokenAccess(article) || premiumUser)
              ? <Fragment>
                <PrevNextBox article={article}/>
                <DigimagBox article={article}/>
              </Fragment>
              : null
            }
            {!payWall
              ? <div styleName="top-margin"><PopularPosts doneLoading={() => {}}/></div>
              : null}
            {!isAd && <ArticleComments articleId={id}/>}
          </div>

        </div>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  FeatureArticle,
  ErrorPlaceholder()
)
