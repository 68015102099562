import { getEnv } from '@otavamedia/om-component-library/lib/util/env'
import jsonp from 'jsonp'
import HTML2Dom from 'html-dom-parser'
import _get from 'lodash/get'

function simplifyNodeTree (result, node) {
  let x = {}
  let name = 'text'
  if (node.children) Object.assign(x, node.children.reduce(simplifyNodeTree, {}))
  if (node.name) {
    name = node.name
    x.attribs = node.attribs
  } else if (node.data && node.data.trim()) {
    x = node.data.trim()
  } else {
    return result
  }
  if (!result[name]) {
    result[name] = []
  }
  result[name].push(x)

  return result
}

function nodeTreeToObject (doc) {
  return {
    title: _get(doc, 'li[0].h3[0].a[0].text[0]'),
    categories: (_get(doc, 'li[0].h4[0].a') || []).map((a) => ({ url: a.attribs.href, name: a.text[0] })),
    img: _get(doc, 'li[0].a[0].img[0].attribs.src'),
    text: _get(doc, 'li[0].p[0].a[0].text[0]'),
    url: _get(doc, 'li[0].a[0].attribs.href') || _get(doc, 'li[0].h3[0].a[0].attribs.href'),
    date: (_get(doc, 'li[0].div[0].div') || []).filter((div) => div.text && div.text[0]).map((div) => div.text[0])[0],
    paywall: !!(_get(doc, 'li[0].div[0].div') || []).filter((div) => div.attribs && div.attribs.class === 'maksullinen-artikkeli').length ||
      !!(_get(doc, 'li[0].div[0].span') || []).filter((span) => span.attribs && span.attribs.class === 'paywall-meta').length,
    comments: (_get(doc, 'li[0].div[0].div') || []).filter((div) => div.attribs && div.attribs.class === 'list-meta-commentcount').map((div) => div.a[0])
  }
}

async function solrSearch (searchString, sort, filter, start = '0', limit = '12', category, onlyPaid) {
  filter = (filter || '').trim()

  // Force the search index to return data for only specific WP blogs. A single
  // WP multisite installation can serve many different React frontend apps for
  // many different brands (e.g. Tekniikan Maailma and Rakennusmaailma). We
  // usually index all WP blogs' data into a single Solr search index. Therefore
  // we want to limit the search results to only those WP blogs that match
  // the current React app's brand.
  const wpBlogIds = process.env.SOLR_SEARCH_WP_BLOG_IDS.trim().split(/\s*,\s*/g)

  filter += (filter ? ' AND ' : '') + 'blog_id:(' + wpBlogIds.join(' OR ') + ')'

  try {
    return await new Promise((resolve, reject) =>
      jsonp(getSolrURL(window.om_constants.solrUrl + (onlyPaid ? 'issue_page' : 'all') + '/select?sort=' + encodeURIComponent(sort) + '&fq=' + encodeURIComponent(filter) +
        (category ? '&fq=category:("' + encodeURIComponent(category) + '")' : '') +
        '&rows=' + encodeURIComponent(limit) + '&start=' + encodeURIComponent(start) +
        '&q=' + encodeURIComponent(searchString)), { param: 'json.wrf' }, (err, data) => {
        if (err) {
          return reject(err)
        }
        if (data.response && data.response.docs) {
          let searchResults = data.response.docs.map((doc) => ({ ...doc, html: HTML2Dom(doc.html).reduce(simplifyNodeTree, {}) }))
          searchResults = searchResults.map((data) => ({ ...data, html: nodeTreeToObject(data.html) }))
          resolve({ docs: searchResults, numFound: data.response.numFound, categoryStats: data.facet_counts.facet_fields.category })
        } else if (data.grouped) {
          let searchResults = data.grouped.issue_group_id.groups.map((group) => group.doclist)
          searchResults = searchResults.map((group) => ({
            issue_name: group.docs[0].issue_name,
            docs: group.docs.map((doc) => ({ ...doc, html: nodeTreeToObject(HTML2Dom(doc.html).reduce(simplifyNodeTree, {})) }))
          }))
          resolve({ docs: searchResults, numFound: data.grouped.issue_group_id.ngroups, categoryStats: [] })
        }
      }))
  } catch (e) {
    console.log('exception ' + e)
    return []
  }
}

const getSolrURL = (path = '') => (({
  development: 'https://prod2.vik.fi',
  production: 'https://prod2.vik.fi',
  test: 'https://prod2.vik.fi',
})[getEnv()]) + path

export default solrSearch
