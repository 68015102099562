import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TabbedListWidget from '@otavamedia/om-component-library/lib/TabbedListWidget'

import { PaywallIcon, SubscriberIcon } from '../../widgets/Icons'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { errors as err } from '@otavamedia/om-component-library/lib/lib/errors'
import { Link } from '../util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import { ShowAll } from '../../widgets/ShowAll'

import './PopularPosts.scss'
import track from 'react-tracking'

const StyledTabbedListWidget = styled(TabbedListWidget)`
  background: white !important;
  margin-bottom: 2.5rem;

  .header {
    font-family: "IdealSans-Bold";
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 1rem;
    margin-top: 0;
    color: black;
  }

  .tabs {
    .tab-header {
      > div {
        button {
          height: 2.25rem;
          background: white;
          border: 1px solid lightgrey;
          border-bottom: none;
        }
        button:hover {
          color: #565e70;
          transition: color 0.1s ease;
        }

        button:focus {
          outline: none;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 4px solid ${props => props.theme.colourDark};
        }

        &.selected:after {
          border-bottom: 4px solid var(--colour-primary);
        }
        &.selected button {
          color: var(--colour-primary);
        }
        &:first-child button {
        }

        &:last-child button {
        }
      }
    }
    .tab-content-list {
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
      padding: 8px 24px;
      ul {
        margin-top: 0;
        margin-bottom: 0;
      }

      .tab-content-list-item {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid ${props => props.theme.colourTmLightgrey};
        &:last-child {
          border-bottom: none;
        }
        .number {
          font-family: 'Noto Sans', sans-serif;
          font-weight: 900; 
          font-size: 30px;
          line-height: 32px;
          padding-right: 0.75rem;
          padding-left: 0.25rem;
          color: #716D64;
        }

        .title {
          display: flex;
          align-items: center;
          flex-flow: row;
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
          }

          a:hover h5 {
            color: ${props => props.theme.colourTitleHover};
            transition: color 0.1s ease;
          }

          > span {
            border: none;
          }
          span:first-child {
            padding-right: 0.5rem;
          }
          span:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }
`

const PopularPaid = styled(StyledTabbedListWidget)`
  h4.header {
    font-family: "IdealSans-Bold";
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 0;
    margin-bottom: 0;
    div {
      width: 16px;
      height: 16px;
      margin-right: 0.75rem;
    }
  }
`
const PaidWeekOnly = styled(StyledTabbedListWidget)`
  h4.header {
    font-family: "IdealSans-Bold";
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 0;
    margin-bottom: 0;
    div {
      width: 16px;
      height: 16px;
      margin-right: 0.75rem;
    }
  }
  .tabs {

    .tab-header {
      border-bottom: 3px solid ${props => props.theme.colourPrimary};
      > div {
        display: none;
      }
    }
  }
`

const WeekOnly = styled(StyledTabbedListWidget)`
  background: white !important;
  margin-bottom: 1.5rem;

  .header {
    font-family: "IdealSans-Bold";
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    margin-top: 0;
  }

  .tabs {

    .tab-header {
      border-bottom: 3px solid ${props => props.theme.colourPrimary};
      > div {
        display: none;
      }
    }
    .tab-content-list {
      margin-top: 0;
      ul {
        margin-top: 0;
      }
      .tab-content-list-item {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid ${props => props.theme.colourLightgrey};

        .number {
          font-size: 30px;
          line-height: 32px;
          padding-right: 0.75rem;
          padding-left: 0.25rem;
          color: ${props => props.theme.colourPrimary};
        }

        .title {
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
          }

          a:hover h5 {
            color: ${props => props.theme.colourTitleHover};
            transition: color 0.1s ease;
          }

          > span {
            border: none;
          }
          span:first-child {
            padding-right: 0.5rem;
          }
          span:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }
`
// eslint-disable-next-line react/prop-types
const ItemComponent = ({ article }) => {
  return (
    <div className="title">
      <Fragment>
        <Link to={article}>
          <h5>
            <span><HTML>{article.title}</HTML></span>
            {!!article.forSubscribers && (
              <div styleName="subscriber-icon-container"><SubscriberIcon/></div>
            )}
          </h5>
        </Link>
      </Fragment>
    </div>
  )
}
ItemComponent.propTypes = {
  article: PropTypes.object,
}

/**
 * Displays one or several tabs of links to most popular posts. Can display either all posts or paid
 * only ("tähtijutut").
 */
class PopularPosts extends Component {
  _mounted = false
  STATUS = {
    NOT_LOADED: 0,
    DONE: 1,
    ERROR: 2,
  }

  tabNameMap = {
    // 'hour': '1 h',
    day: '24 h',
    week: 'Viikko',
  }

  tabNameMapPaid = {
    // 'day': '24 h',
    week: 'Viikko',
    month: 'Kuukausi',
  }

  tabNameWeekOnly = {
    week: 'Viikko',
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedColumn: (props.paidOnly || props.weekOnly) ? 'week' : 'day',
      status: this.STATUS.NOT_LOADED
    }
  }

  loadData (props) {
    WP.getMostPopular('0', props.paidOnly, props.limit > 5, 10)
      .then((popularArticles) => {
        if (this._mounted) {
          this.setState({ popularArticles, status: popularArticles ? this.STATUS.DONE : this.STATUS.ERROR })
          this.props.doneLoading()
        }
      })
      .catch((e) => this._mounted && this.setState({ status: this.STATUS.ERROR, error: e }) && this.props.doneLoading())
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.paidOnly !== this.props.paidOnly || props.limit !== this.props.limit) {
      this.loadData(props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.paidOnly !== this.props.paidOnly || nextProps.limit !== this.props.limit || nextState !== this.state
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { status, popularArticles } = this.state
    const { paidOnly, limit, weekOnly } = this.props
    const Element = paidOnly ? (weekOnly ? PaidWeekOnly : PopularPaid) : (weekOnly ? WeekOnly : StyledTabbedListWidget)
    const headers = weekOnly ? this.tabNameWeekOnly : (paidOnly ? this.tabNameMapPaid : this.tabNameMap)

    if (status === this.STATUS.ERROR) throw err.popularLoadError({ previousError: this.state.error })

    const tabs = popularArticles && Object.keys(headers).map((key) => {
      const column = popularArticles[key]

      if (column.length === 0) {
        return {
          title: headers[key],
          contentComponent: () => <p style={{ textAlign: 'center' }} className="empty">Tälle ajankohdalle ei vielä ole suosituimpia artikkeleita.</p>,
        }
      }

      return {
        title: headers[key],
        ItemComponent,
        itemComponentProps: {},
        itemProps: column.slice(0, limit || 10).map((article) => {
          return { article }
        }),
      }
    })

    return !!tabs && (
      <Fragment>
        <Element
          id="popular-posts"
          title={paidOnly ? <Fragment><PaywallIcon/>Luetuimmat tähtijutut</Fragment> : 'Suosituimmat'}
          tabs={tabs}
        />
        {limit || paidOnly ? null : <ShowAll url="/suosituimmat"/>}
      </Fragment>
    )
  }
}

PopularPosts.propTypes = {
  doneLoading: PropTypes.func.isRequired,
  paidOnly: PropTypes.bool,
  limit: PropTypes.number,
  weekOnly: PropTypes.bool,
}

export default track({ gtmContext: ['PopularPosts'] })(withErrorBoundary(
  PopularPosts,
  ErrorPlaceholderBox('Suosittuja ei saatu ladattua'),
))
